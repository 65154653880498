import AppInstall from "./AppInstall";
import Footer from "./Footer";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";
import React, { useState, useEffect } from "react";
import BlogHeroSection from "./BlogHeroSection";
import BlogDetailsHero from "./BlogDetailsHero";
import BlogList from "./BlogList";
import { useParams } from "react-router";
import BlogDetailsPage from "./BlogDetailsPage";

function Blogs() {
  const { id } = useParams();
  const [listData, setListData] = useState({});
  useEffect(() => {
    let pageTitle =
      "Watch Free Movies and TV Shows Online | Free Streaming Video | Fawesome";
    let pageDescription =
      "Watch free movies & TV shows across 25+ genres on Fawesome | No Subscription | No Registration | Instant Streaming | Full movies in HD";
    let pageKeywords =
      "Free movies online, Watch free movies, Watch free movies online, Free movies, Free movies online sites, Action movies, Mystery movies free, horror movies free, Free movies on youtube, Free shows on youtube";

    const metaOgTitle = document.querySelector('meta[property="og:title"]');
    const metaTwitterTitle = document.querySelector(
      'meta[name="twitter:title"]'
    );

    //document.title = pageTitle;
    /*if (metaOgTitle) {
      metaOgTitle.setAttribute("content", pageTitle);
    }
    if (metaTwitterTitle) {
      metaTwitterTitle.setAttribute("content", pageTitle);
    }*/

    const updateMetaTag = (name, content) => {
      const metaTag = document.querySelector(
        `meta[name="${name}"], meta[property="${name}"]`
      );
      if (metaTag) {
        metaTag.setAttribute("content", content);
      }
    };

    // updateMetaTag("description", pageDescription);
    // updateMetaTag("og:description", pageDescription);
    // updateMetaTag("twitter:description", pageDescription);
    // updateMetaTag("keywords", pageKeywords);

    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const getDetails = myJson.filter((val) => val.id == id);
        setListData(getDetails[0]);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page">
        <AppInstall></AppInstall>
        <BlogHeroSection />
        <BlogList />
        {/* {id ? <BlogDetailsHero data={listData} /> : <BlogHeroSection /> }
        {id ? <BlogDetailsPage data={listData} /> : <BlogList /> } */}
        <Footer></Footer>
        <UserConsent></UserConsent>
      </div>
      <LoaderAnim id="wanim"></LoaderAnim>
    </React.Fragment>
  );
}

const wait = (delay = 0) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) => {
  let element =
    typeof elementOrSelector === "string"
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;
  if (element) {
    element.style.display = visible ? "block" : "none";
  }
  /*(typeof elementOrSelector === "string"
    ? document.querySelector(elementOrSelector)
    : elementOrSelector
  ).style.display = visible ? "block" : "none";*/
};

wait(1).then(() => {
  setVisible(".page", false);
  setVisible("#loading", true);
  /*try {
    setVisible(".page", false);
  } catch (error) {}
  try {
    setVisible("#loading", true);
  } catch (error) {}*/
});

document.addEventListener("DOMContentLoaded", () =>
  wait(2000).then(() => {
    setVisible(".page", true);
    setVisible("#loading", false);
  })
);
export default Blogs;

import React, { useEffect, useState, useRef } from "react";
import Pagination from "react-bootstrap/Pagination";

function BlogList() {
  const ref = useRef(null);
  const [state, setState] = useState({
    data: [],
    limit: 6,
  });
  //const [limit, setLimit] = useState(6);
  const [activePage, setActivePage] = useState(1);
  const [listData, setListData] = useState([]);
  const listDataRef = useRef(listData); // Ref to track the latest listData
  const updateLimit = () => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const newLimit = isMobile ? 3 : 6; // 3 for mobile, 6 for larger screens

    setListData((prevListData) => {
      setState((prevState) => ({ ...prevState, limit: newLimit }));
      const out = prevListData.slice(0, newLimit);
      setState((prev) => ({
        ...prev,
        data: out,
      }));
      return prevListData; // Return the same value since we're not updating it here
    });
  };
  useEffect(() => {
    updateLimit();
    window.addEventListener("resize", updateLimit);
    return () => {
      window.removeEventListener("resize", updateLimit); // Clean up listener on unmount
    };
  }, []);
  useEffect(() => {
    if (listData && listData.length == 0) {
      fetch("./assets/data/blogs.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          const isMobile = window.matchMedia("(max-width: 768px)").matches;
          const newLimit = isMobile ? 3 : 6; // 3 for mobile, 6 for larger screens
          setListData(myJson);
          const out = myJson.slice(0, newLimit);
          setState((prev) => ({
            ...prev,
            data: out,
          }));
        });
    }

    // Cleanup listener on component unmount
    // return () => window.removeEventListener("resize", updateLimit);
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const offset = pageNumber * state.limit;
    const out = listData.slice(offset - state.limit, offset);
    setState((prev) => ({
      ...prev,
      data: out,
    }));
    ref.current?.scrollIntoView({ behavior: "smooth" });
    //setState((prev) => ({ ...prev, activePage: pageNumber }));
  };
  //console.log("state.limit >> ", state.limit);
  //console.log("state.data >> ", state.data);
  return (
    <section className="container blog-posts" ref={ref}>
      <div className="row mt-2">
        {state.data.length > 0 &&
          state.data.map((item, index) => {
            return [
              index % 2 === 0 ? (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt={item.alt_text} />
                    </div>
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <img src="./assets/images/ico-calendar.png"></img>
                          <span className="date">{item.date}</span>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.short_description,
                        }}
                      />
                      <a
                        // href={`./blogs/${item.id}`}
                        href={`./blogs/${item.id}`}
                        className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </article>
              ) : (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <img src="./assets/images/ico-calendar.png"></img>
                          <span className="date">{item.date}</span>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.short_description,
                        }}
                      />
                      <a
                        href={`./blogs/${item.id}`}
                        className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                      >
                        READ MORE
                      </a>
                    </div>
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt={item.alt_text} />
                    </div>
                  </div>
                </article>
              ),
            ];
          })}
      </div>
      {Array(Math.round(listData.length / state.limit)).length > 0 && (
        <Pagination className="px-4 d-flex justify-content-center">
          <Pagination.Prev
            disabled={activePage > 1 ? false : true}
            onClick={() => handlePageChange(activePage - 1)}
          />
          {[...Array(Math.round(listData.length / state.limit))].map(
            (_, index) => {
              return (
                <Pagination.Item
                  onClick={() => handlePageChange(index + 1)}
                  key={index + 1}
                  active={index + 1 === activePage}
                >
                  {index + 1}
                </Pagination.Item>
              );
            }
          )}
          <Pagination.Next
            disabled={
              activePage < Math.round(listData.length / state.limit)
                ? false
                : true
            }
            onClick={() => handlePageChange(activePage + 1)}
          />
        </Pagination>
      )}
    </section>
  );
}
export default BlogList;

import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
function HeroSection() {
  let heroImage = "./assets/images/heroImage.jpg";
  let logoFawesome = "./assets/images/logo-fawesome.svg";
  let searchIcon = "./assets/images/search.svg";
  let browseIcon = "./assets/images/browse.svg";
  let debounceTimer;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const navLinks = document.querySelectorAll(".bg-body-tertiary .nav-link");
    const searchInput = document.querySelectorAll(
      ".bg-body-tertiary #search-input"
    );
    navLinks.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-nav-link");
    });
    searchInput.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-search-text");
    });
  }, []);

  const menuInfo = [
    { name: "Home", link: `${document.location.origin}/home/` },
    {
      name: "Collections",
      link: `${document.location.origin}/categories/collections`,
    },
    { name: "Shows", link: `${document.location.origin}/categories/shows` },
    {
      name: "Best Movies",
      link: `${document.location.origin}/categories/best-movies`,
    },
    {
      name: "Recommended",
      link: `${document.location.origin}/categories/recommended`,
    },
    { name: "Search", link: `${document.location.origin}/home/` },
  ];
  return (
    <>
      <Navbar className="bg-body-tertiary navbar-dark" expand="xl">
        <div className="container-fluid">
          <Navbar.Brand href={`${document.location.origin}/home/`}>
            {/* <a
              className="navbar-brand"
              rel="noopener noreferrer"
              onClick={handleClick}
            > */}
            <img
              src={logoFawesome}
              className="img-fluid fw-logo"
              alt="Fawesome"
              // style="opacity: 1;"
            />
            {/* </a> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Collections</Nav.Link>
            <Nav.Link href="#link">Shows</Nav.Link>
            <Nav.Link href="#link">Best Movies</Nav.Link>
            <Nav.Link href="#link">Recommended</Nav.Link> */}
            {getNamedMenu()}
            {/* <Nav.Link>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    value=""
                    id="search-input"
                    placeholder="Search"
                  />
                </div>
              </div>
            </Nav.Link> */}

            {/* <Nav.Link href="#link">About</Nav.Link> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="heroSection">
        <div className="fCorLeft"></div>

        <div className="herotitleCont">
          <h1 className="herotitle">Free Instant Streaming!</h1>
          <h3>No registration, No subscription.</h3>
          <p className="cta-intro">
            {/* <a className="btn btn-primary">Browse Now</a> */}

            <a
              className="btn btn-primary btn-browse-now gtm-lp gtm-browse-now"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <img
                src={browseIcon}
                className="img-fluid"
                style={{ paddingRight: 15 }}
                alt="Fawesome"
                // style="opacity: 1;"
              />
              Browse Now
            </a>
          </p>
        </div>
      </div>

      <div className="heropicCont">
        <img src={heroImage} className="heropic" alt="fawesome" />
      </div>
    </>
  );

  function handleClick() {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    var newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    var homePath = "/home/";
    if (newUrl.endsWith("/")) {
      homePath = "home/";
    }
    window.location.href = newUrl + homePath + qs;
  }

  function getNamedMenu() {
    return (
      <>
        {menuInfo.map((item, index) =>
          item.name == "Search" ? (
            <Nav.Link key={index}>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    id="search-input"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </Nav.Link>
          ) : (
            <Nav.Link href={item.link} key={index}>
              {item.name}
            </Nav.Link>
          )
        )}
      </>
    );
  }

  function handleChange(event) {
    var searchText = event.target.value;
    setInputValue(event.target.value);
    // Set debounce delay in milliseconds
    var debounceDelay = 2000;
    // Check if searchText has at least 2 letters
    if (searchText.length >= 2) {
      // Clear previous debounce timer
      clearTimeout(debounceTimer);
      // Set new debounce timer
      debounceTimer = setTimeout(function () {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }, debounceDelay);
    }
  }

  function handleKeyDown(event) {
    var searchText = event.target.value;
    clearTimeout(debounceTimer);
    if (event.key === "Enter") {
      if (searchText.length >= 2) {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }
    }
  }
}
export default HeroSection;
